@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-Thin.ttf");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-ExtraLight.ttf");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-ExtraBold.ttf");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-Black.ttf");
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-Thin.ttf");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-ExtraLight.ttf");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-Black.ttf");
  font-weight: 900;
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
}
